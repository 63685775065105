import React from "react";
import { Container } from '@material-ui/core';

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
  };
  

const Screen = ({ style, children }) => (
    <Container style={{...styles.container, ...style}} maxWidth={false} disableGutters>{children}</Container>
);

export default Screen;