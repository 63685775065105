import React, { useContext } from 'react';
import { TranslationContext } from '../../translation/translationContext';
import flagEnglish from '../../assets/images/flag-english.svg';
import flagSwedish from '../../assets/images/flag-swedish.svg';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    flag: {
        marginLeft: 4,
        marginRight: 4,
        height: 24,
        width: 48,
        opacity: 0.5
    },
    currentFlag: {
        opacity: 1
    }
};

const flags = [
    { src: flagEnglish, alt: 'english', locale: 'en' },
    { src: flagSwedish, alt: 'swedish', locale: 'sv' },
];

const TranslationView = () => {
    const { locale, changeLocale } = useContext(TranslationContext) ?? {};

    return (
        <div style={styles.container}>
            {flags.map(({ src, alt, locale: flagLocale }) => {
                const isCurrent = locale === flagLocale;
                const extraStyle = isCurrent ? styles.currentFlag : {};
                return (
                    <img
                        style={{...styles.flag, ...extraStyle}}
                        key={alt}
                        src={src}
                        alt={alt}
                        onClick={() => changeLocale(flagLocale)}
                    />
                )
            })}
        </div>
    )
};

export default TranslationView;