export const VERY_LIGHT_BLUE = '#F6FCFF';
export const LIGHT_BLUE = '#E5F4FE';
export const DARK_BLUE = '#2663BE';
export const MEDIUM_BLUE = '#5A96BE';
export const BLACK = '#000000';
export const WHITE = '#ffffff';
export const PRICE_BLUE = '#51ABE7';
export const SKY_BLUE = '#4E9CF6';
export const PURPLE = '#343D94';

// TODO: Better names